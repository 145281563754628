import React, { useState, useEffect } from 'react';
import Header from './Header';
import { useAuth } from '../hooks';
import Sidebar from './Sidebar';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isMediumScreen, setIsMediumScreen] = useState(false);
    // const [isLoginPage, setIsLoginPage] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth >= 1024) {
                setSidebarOpen(true); // Full size on desktop
                setIsMediumScreen(false);
            } else if (screenWidth >= 600 && screenWidth < 1024) {
                setSidebarOpen(false); // Collapsed on medium screens
                setIsMediumScreen(true);
            } else if (screenWidth < 600) {
                setSidebarOpen(false); // Hidden on mobile
                setIsMediumScreen(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // dark:bg-darkBg dark:text-white
    return (
        <div className="min-h-screen bg-[#f9f9f9] text-gray-900 relative">
            {isAuthenticated && <Header toggleSidebar={toggleSidebar} />}
            {isAuthenticated && (isMediumScreen || sidebarOpen) && (
                <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            )}
            <div className={`transition-all duration-300 ${sidebarOpen ? 'lg:ml-64' : 'ml-1'} p-1 pt-20`}>
                {children}
            </div>
        </div>
    );
};

export default Layout;
