import { getDataWithQueryParams, getData, postData, putData, deleteData } from './api';

export interface User {
    id?: string;
    name: string;
    email: string;
    password: string;
    phoneNumber?: string;
}

export const getSchools = async (data: any) => {
    return await getDataWithQueryParams(`/schools`, data);;
};

export const getSchool = async (id: string) => {
    return await getData(`/schools/`, id);
};

export const addSchool = async (user: User) => {
    return await postData('/schools', user);
};

export const updateSchool = async (id: number, user: User) => {
    return await putData(`/schools/${id}`, user);
};

export const deleteSchool = async (id: string) => {
    return await deleteData(`/schools/`, id);
};
