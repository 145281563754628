import { io, Socket } from "socket.io-client";
import { socketUrl } from "./api";

// const socket: Socket = io("http://localhost:3090"); // Backend URL

const socket: Socket = io(socketUrl, {
    transports: ["websocket", "polling"],
});

export default socket;
