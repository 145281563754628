export const CLASS_OPTIONS = "6789".split("").concat(["10", "11", "12"]);
export const SECTION_OPTIONS = "ABC".split("");

// const sampleSpeeches = [
//   "The quick brown fox jumps over the lazy dog.",
//   "To be or not to be, that is the question.",
//   "The only limit to our realization of tomorrow is our doubts of today.",
//   "In the end, we will remember not the words of our enemies, but the silence of our friends.",
//   "Success is not final, failure is not fatal: It is the courage to continue that counts.",
// ];

// const topicOptions = [
//     { label: 'My Self', value: 'My Self' },
//     { label: 'Family', value: 'Family' },
//     { label: 'Friends', value: 'Friends' },
//     { label: 'School', value: 'School' },
// ];

export const SPEECH_TOPIC_OPTIONS = [
  {
    value: "The quick brown fox jumps over the lazy dog.",
    type: "Reading",
  },
  {
    value: "To be or not to be, that is the question.",
    type: "Reading",
  },
  {
    value:
      "The only limit to our realization of tomorrow is our doubts of today.",
    type: "Reading",
  },
  {
    value:
      "In the end, we will remember not the words of our enemies, but the silence of our friends.",
    type: "Reading",
  },
  {
    value:
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    type: "Reading",
  },
  {
    value: "My Self",
    type: "Speaking",
  },
  {
    value: "Family",
    type: "Speaking",
  },
  {
    value: "Friends",
    type: "Speaking",
  },
  {
    value: "School",
    type: "Speaking",
  },
];
