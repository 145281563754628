import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faChalkboardTeacher, faBook, faUser } from '@fortawesome/free-solid-svg-icons';

const Dashboard: React.FC = () => {
    const students = [
        { firstName: 'Alice', lastName: 'Johnson', grade: 'A', age: 15 },
        { firstName: 'Bob', lastName: 'Smith', grade: 'B', age: 16 },
    ];

    const teachers = [
        { firstName: 'John', lastName: 'Doe', subject: 'Math' },
        { firstName: 'Jane', lastName: 'Smith', subject: 'Science' },
    ];

    return (
        <div className="p-6">
            <h1 className="text-3xl font-semibold mb-6">Dashboard</h1>

            {/* Dashboard Cards - Adjusted to 2 columns per row */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-6"> {/* 2-column layout */}
                <div className="bg-white dark:bg-darkBg shadow-lg rounded-lg p-8 flex items-center justify-between">
                    <div>
                        <h2 className="text-3xl font-semibold">Students</h2>
                        <p className="text-5xl mt-4 font-bold">450</p>
                    </div>
                    <FontAwesomeIcon icon={faUserGraduate} className="text-7xl text-blue-500" />
                </div>
                <div className="bg-white dark:bg-darkBg shadow-lg rounded-lg p-8 flex items-center justify-between">
                    <div>
                        <h2 className="text-3xl font-semibold">Teachers</h2>
                        <p className="text-5xl mt-4 font-bold">30</p>
                    </div>
                    <FontAwesomeIcon icon={faChalkboardTeacher} className="text-7xl text-green-500" />
                </div>
                <div className="bg-white dark:bg-darkBg shadow-lg rounded-lg p-8 flex items-center justify-between">
                    <div>
                        <h2 className="text-3xl font-semibold">Exams</h2>
                        <p className="text-5xl mt-4 font-bold">25</p>
                    </div>
                    <FontAwesomeIcon icon={faBook} className="text-7xl text-yellow-500" />
                </div>
                <div className="bg-white dark:bg-darkBg shadow-lg rounded-lg p-8 flex items-center justify-between">
                    <div>
                        <h2 className="text-3xl font-semibold">Online Teachers</h2>
                        <p className="text-5xl mt-4 font-bold">12</p>
                    </div>
                    <FontAwesomeIcon icon={faUser} className="text-7xl text-red-500" />
                </div>
            </div>

            {/* Students Table */}
            <div className="bg-white dark:bg-darkBg shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-2xl font-semibold mb-4">Recent Students</h2>
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="border p-2">First Name</th>
                            <th className="border p-2">Last Name</th>
                            <th className="border p-2">Grade</th>
                            <th className="border p-2">Age</th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student, index) => (
                            <tr key={index}>
                                <td className="border p-2">{student.firstName}</td>
                                <td className="border p-2">{student.lastName}</td>
                                <td className="border p-2">{student.grade}</td>
                                <td className="border p-2">{student.age}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Teachers Table */}
            <div className="bg-white dark:bg-darkBg shadow-lg rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Recent Teachers</h2>
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="border p-2">First Name</th>
                            <th className="border p-2">Last Name</th>
                            <th className="border p-2">Subject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teachers.map((teacher, index) => (
                            <tr key={index}>
                                <td className="border p-2">{teacher.firstName}</td>
                                <td className="border p-2">{teacher.lastName}</td>
                                <td className="border p-2">{teacher.subject}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;
