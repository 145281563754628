import { getDataWithQueryParams, getData, postData, putData, deleteData, fileUpload } from './api';

export interface User {
    id?: string;
    name: string;
    email: string;
    password: string;
    phoneNumber?: string;
}

export const getStudents = async (data: any) => {
    return await getDataWithQueryParams(`/students`, data);;
};

export const getStudent = async (id: string) => {
    return await getData(`/students/`, id);
};

export const addStudent = async (user: User) => {
    return await postData('/students', user);
};

export const updateStudent = async (id: number, user: User) => {
    return await putData(`/students/${id}`, user);
};

export const deleteStudent = async (id: string) => {
    return await deleteData(`/students/`, id);
};

export const bulkUpdateStudents = async (data: any, config: any) => {
    return await fileUpload(`/students/bulk-upload`, data, config);
};