import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
import { useToast } from "../../hooks";
import { useApi, useMutation } from "../../hooks/useNew";
import { getExams, addExam, updateExam, deleteExam } from "../../services/examService";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from 'react-router-dom';
import { CLASS_OPTIONS, SECTION_OPTIONS, SPEECH_TOPIC_OPTIONS } from '../../constants';
import { humanReadableDate } from '../../utils';
import { IExam } from '../../interfaces';
import CustomSelect from '../../components/CustomSelect';

// Define the Student type
interface Student {
    id: number;
    name: string;
    classId: string;
}

// Define the Exam type
interface ICreateExam {
    id?: string;
    title: string;
    type: string;
    topic: string;
    duration: number;
    class: number;
    description?: string;
    section?: string;
    isPractice?: boolean;
}

const typeOptions = [
    { label: 'Speaking', value: 'Speaking' },
    // { label: 'Writing', value: 'Writing' },
    { label: 'Reading', value: 'Reading' },
    // { label: 'Listening', value: 'Listening' },
];

// const topicOptions = [
//     { label: 'My Self', value: 'My Self' },
//     { label: 'Family', value: 'Family' },
//     { label: 'Friends', value: 'Friends' },
//     { label: 'School', value: 'School' },
// ];

const columns: ColumnDef<IExam>[] = [
    {
        accessorKey: "title",
        header: "Exam Name",
        cell: (info) => {
            const originalData = info?.row?.original || {};
            return (
                <Link to={`/exams/take-exam/${originalData.id}`} className="flex items-center text-sky-500">
                    {originalData.title}
                </Link>
            );
        }
    },
    {
        accessorKey: "type",
        header: "Type",
    },
    {
        accessorKey: "date",
        header: "Created Date",
        cell: (info) => {
            const originalData = info?.row?.original || {};
            return humanReadableDate(originalData.createdAt);
        }
    },
    {
        accessorKey: "class",
        header: "Class",
    },
    {
        accessorKey: "section",
        header: "Section",
    },
    {
        accessorKey: "totalStudents",
        header: "Total Students"
    },
    {
        accessorKey: "totalStudentsTakeExam",
        header: "Total Take Exam"
    }
];

const CreateExam: React.FC<{isPractice?: boolean}> = ({isPractice = false}) => {
    const [exam, setExam] = useState<ICreateExam>({
        title: '',
        type: '',
        topic: '',
        duration: 2,
        class: 0,
        description: '',
        section: '',
        isPractice: isPractice
    });
    const { showToast, updateToast, dismissToast, } = useToast();
    const {
        data: exams = [],
        isLoading: isFetchingExams,
        refetch: fetchExams,
        pagination,
    } = useApi<IExam[]>(
        "ExamList",
        () =>
            getExams({
                query: "",
                pageNumber: 1,
                pageSize: 100,
                isPractice: isPractice
            }),
        {
            enabled: false,
            onSuccess: (response: any) => {
                console.log(response);
                return response;
            },
            onError: (error: Error) => {
                showToast("error", error.message);
            },
        },
    );

    const addKey = "AddExam";
    const { mutate: addExamMutation, isLoading: isAddingExam } =
        useMutation(addKey, (data) => addExam(data), {
            onSuccess: (response: any) => {
                showToast("success", response.message);
                clearForm();
                // closeModal();
                fetchExams();
            },
            onError: (error: any) => {
                showToast("error", error.error || error.message);
            },
            onMutate: (data) => {
                console.log(data);
                showToast("info", "Adding Exam...", addKey);
            },
            onSettled: () => {
                dismissToast(addKey);
            },
        });

    const updatekey = "UpdateExam";
    const { mutate: updateExamMutation, isLoading: isUpdatingExam } =
        useMutation(
            updatekey,
            (data) =>
                updateExam(data.id, {
                    ...data,
                    id: undefined,
                }),
            {
                onSuccess: (response: any) => {
                    showToast("success", response.message);
                    // closeModal();
                    clearForm();
                    fetchExams();
                },
                onError: (error: any) => {
                    showToast("error", error.error || error.message);
                },
                onMutate: (data) => {
                    console.log(data);
                    showToast("info", "Updating Exam...", updatekey);
                },
                onSettled: () => {
                    dismissToast(updatekey);
                },
            },
        );

    useEffect(() => {
        fetchExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPractice]);

    const clearForm = () => {
        setExam({
            title: '',
            type: '',
            topic: '',
            duration: 2,
            class: 0,
            description: '',
            section: '',
            isPractice: isPractice
        });
    };

    const handleCreateExam = (e: React.FormEvent) => {
        e.preventDefault();
        if (exam.id) {
            updateExamMutation({
                id: exam.id,
                title: exam.title,
                type: exam.type,
                topic: exam.topic,
                duration: 2,
                class: exam.class,
                section: exam.section ? exam.section : undefined,
                description: exam.description ? exam.description : undefined,
                isPractice: isPractice
            });
        } else {
            addExamMutation({
                title: exam.title,
                type: exam.type,
                topic: exam.topic,
                duration: 2,
                class: exam.class,
                section: exam.section ? exam.section : undefined,
                description: exam.description ? exam.description : undefined,
                isPractice: isPractice
            });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setExam(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (obj: { name: string, value: string; }) => {
        const { name, value } = obj;
        // setExam({ ...exam, [name]: value });
        setExam(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="p-4 m-6 bg-white dark:bg-darkBg shadow-lg rounded-lg">
            <h1 className="text-2xl font-semibold mb-4">Create {isPractice ? 'a' : 'an'} {isPractice ? 'Practice' : 'Exam'}</h1>
            <form name="Exam" onSubmit={handleCreateExam}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label>Exam Name</label>
                        <input
                            type="text"
                            className="w-full p-2 mt-2 border rounded-md"
                            value={exam?.title}
                            name="title"
                            onChange={handleInputChange}
                            required
                        // onChange={(e) => setExamName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Exam Type</label>
                        <select
                            className="w-full p-2 mt-2 border rounded-md"
                            name="type"
                            value={exam?.type}
                            onChange={(e) => {
                                handleInputChange(e)
                                // handleSelectChange({name: "type", value: e.target.value});
                                handleSelectChange({name: "topic", value: ''});
                            }}
                            required>
                            <option value="">Select Type</option>
                            {typeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    {exam?.type && (
                        <div>
                            <label>Topic</label>
                            <CustomSelect value={{value:exam?.topic, label:exam?.topic}} options={SPEECH_TOPIC_OPTIONS.filter((option) => {
                                return option.type === exam?.type
                            }).map((option) => option && ({ value: option.value, label: option.value }))} onChange={(option:any) => {
                                if(option && typeof option === "object") {
                                    handleSelectChange({name: "topic", value: option.value});
                                };
                                // handleSelectChange({name: "topic", value: option.value});
                            }} />
                            {/* <select
                                className="w-full p-2 mt-2 border rounded-md"
                                name="topic"
                                value={exam?.topic}
                                onChange={handleInputChange} required>
                                <option value="">Select Topic</option>
                                {topicOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select> */}
                        </div>
                    )}
                    {/* {exam?.type === "Reading" && (
                        <div>
                            <label>Topic</label>
                            <textarea
                                className="w-full p-2 mt-2 border rounded-md"
                                name="topic"
                                value={exam?.topic}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    )} */}
                    <div>
                        <label>Class</label>
                        <select
                            className="w-full p-2 mt-2 border rounded-md"
                            name="class"
                            value={exam?.class}
                            onChange={handleInputChange}
                            required>
                            <option value="">Select Class</option>
                            {CLASS_OPTIONS.map((option) => (
                                <option key={option} value={option}>
                                    Class {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Section</label>
                        <select
                            className="w-full p-2 mt-2 border rounded-md"
                            name="section"
                            value={exam?.section}
                            onChange={handleInputChange}>
                            <option value="">Select Section</option>
                            {SECTION_OPTIONS.map((option) => (
                                <option key={option} value={option}>
                                    Section {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* <div>
                    <label>Class</label>
                    <Select
                        value={classOptions.find((option) => option.value === exam.class)}
                        onChange={(selected) => {
                            console.log(selected);
                            // setSelectedClass(selected);
                            handleSelectChange({ name: "class", value: selected?.value });
                        }}
                        options={classOptions.map((option) => ({
                            label: option.label,
                            value: "" + option.value,
                        }))}
                        placeholder="Select class"
                        className="w-full mt-2"
                    />
                </div> */}
                    {/* <div>
                    <label>Section</label>
                    <Select
                        value={exam.section}
                        onChange={(selected) => {
                            console.log(selected);
                            // setSelectedClass(selected);
                            // setExam({ ...exam, section: selected?.value });
                            handleSelectChange({ name: "section", value: selected?.value });
                        }}
                        options={sectionOptions.map((option) => ({
                            label: option.label,
                            value: "" + option.value,
                        }))}
                        placeholder="Select class"
                        className="w-full mt-2"
                    />
                </div> */}
                </div>

                <button
                    type="submit"
                    disabled={isFetchingExams || isAddingExam || isUpdatingExam}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md w-full mt-4"
                >
                    Create {isPractice ? 'Practice' : 'Exam'}
                </button>
            </form>
            <h2 className="text-xl font-semibold mt-6">Created {isPractice ? 'Practice' : 'Exams'}</h2>
            <Table columns={columns} data={exams} onRowClick={(row) => {}} />
        </div>
    );
};

export default CreateExam;
