import React, { useState, useEffect } from 'react';
import { useAuth, useUploadFile, useToast } from "../../hooks";
import { useApi, useMutation } from "../../hooks/useNew";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/react-table";
import Modal from "../../components/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import {
    getStudents,
    addStudent,
    updateStudent,
    bulkUpdateStudents,
} from "../../services/studentService";
import { CLASS_OPTIONS, SECTION_OPTIONS } from '../../constants';
interface Student {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    age: number;
    dateOfBirth: string;
    grade: string;
    class: string;
    section: string;
    rollNumber: number;
    phoneNumber: string;
    mainClass: string;
    assessmentYear: string;
}

const columns: ColumnDef<Student>[] = [
    {
        accessorKey: "firstName",
        header: "First Name",
    },
    {
        accessorKey: "lastName",
        header: "Last Name",
    },
    {
        accessorKey: "class",
        header: "Class",
    },
    {
        accessorKey: "section",
        header: "Section",
    },
    {
        accessorKey: "rollNumber",
        header: "Roll Number",
    },
    {
        accessorKey: "mainClass",
        header: "Main Class",
        cell: (info) => {
            // console.log(info);
            const originalData = info?.row?.original || {};
            return `${originalData.class || ''}${originalData.section || ''}`;
        },
    },
    {
        accessorKey: "assessmentYear",
        header: "Assessment Year",
    },
    // {
    //     accessorKey: "email",
    //     header: "Email",
    // },
    {
        accessorKey: "phoneNumber",
        header: "Phone Number",
    },
];

const Students: React.FC = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const { userRole } = useAuth();
    const { showToast, updateToast, dismissToast, } = useToast();
    const [student, setStudent] = useState<any>({});

    const {
        data: students = [],
        isLoading: isFetchingStudents,
        refetch: fetchStudents,
        pagination,
    } = useApi(
        "StudentList",
        () =>
            getStudents({
                query: "",
                pageNumber: 1,
                pageSize: 100,
            }),
        {
            enabled: false,
            onSuccess: (response: any) => {
                console.log(response);
                return response;
            },
            onError: (error: Error) => {
                showToast("error", error.message);
            },
        },
    );

    const { mutate: addStudentMutation, isLoading: isAddingStudent } =
        useMutation("AddStudent", (data) => addStudent(data), {
            onSuccess: (response: any) => {
                showToast("success", response.message);
                closeModal();
                fetchStudents();
            },
            onError: (error: any) => {
                showToast("error", error.error || error.message);
            },
            onMutate: (data) => {
                console.log(data);
                showToast("info", "Adding Student...", "AddStudent");
            },
            onSettled: () => {
                dismissToast("AddStudent");
            },
        });

    const { mutate: updateStudentMutation, isLoading: isUpdatingStudent } =
        useMutation(
            "UpdateStudent",
            (data) =>
                updateStudent(data.id, {
                    ...data,
                    id: undefined,
                }),
            {
                onSuccess: (response: any) => {
                    showToast("success", response.message);
                    closeModal();
                    fetchStudents();
                },
                onError: (error: any) => {
                    showToast("error", error.error || error.message);
                },
                onMutate: (data) => {
                    console.log(data);
                    showToast("info", "Updating Student...", "UpdateStudent");
                },
                onSettled: () => {
                    dismissToast("UpdateStudent");
                },
            },
        );

    function isFormData(obj: any) {
        return obj instanceof FormData;
    }


    const { uploadFile, uploading, percentage } = useUploadFile('BulkUploadStudents', (data, config) => {
        console.log('isFormData', isFormData(data));
        return bulkUpdateStudents(data, config);
    }, {
        onFire: () => {
            showToast('info', 'Uploading 1%', 'BulkUploadStudents');
        },
        onPercentage: (val: number) => {
            updateToast('info', `Uploading ${val}%`, 'BulkUploadStudents');
        },
        onSuccess: (fileData) => {
            dismissToast('BulkUploadStudents');
            console.log('res', fileData);

            showToast('success', `Total: ${fileData.total || 0} Added: ${fileData.totalAdded || 0} Updated: ${fileData.totalUpdated || 0} Failed: ${fileData.totalError || 0} `);
            fetchStudents();
        },
        onError: (error: any) => {
            dismissToast('BulkUploadStudents');
            showToast('error', error.message ? error.message : error);
        }
    });

    useEffect(() => {
        console.log("Students useEffect");
        fetchStudents();
    }, []);


    // Function to open the modal
    const openModal = (data?: any) => {
        setModalOpen(true);
        if (data) {
            setStudent(data);
        } else {
            setStudent({
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                age: 0,
                dateOfBirth: "",
                grade: "",
                class: "",
                section: "",
                rollNumber: 0,
                phoneNumber: "",
                mainClass: "",
                assessmentYear: 2024,
            });
        }
    };

    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setStudent({ ...student, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const userName = "SCH" + new Date().valueOf().toString();
        if (!student.id) {
            addStudentMutation({
                id: undefined,
                firstName: student.firstName,
                lastName: student.lastName,
                // email: student.email,
                class: student.class,
                section: student.section,
                rollNumber: student.rollNumber,
                phoneNumber: student.phoneNumber,
                username: userName,
                password: userName,
                assessmentYear: 2024
            });
        } else {
            updateStudentMutation({
                id: student.id,
                firstName: student.firstName,
                lastName: student.lastName,
                // email: student.email,
                class: student.class,
                section: student.section,
                rollNumber: student.rollNumber,
                phoneNumber: student.phoneNumber,
                password: undefined,
                assessmentYear: 2024
            });
        }
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) {
            const file = e.target.files[0];
            console.log(file.name, file.size, file.type);

            if (file) {
                const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
                if (!allowedExtensions.exec(file.name)) {
                    showToast('error', 'Please upload file having extensions .csv, .xls, or .xlsx only.');

                    return;
                }
                uploadFile(file);
                console.log('File selected:', file.name);
            }
            e.target.value = ''; // Reset the input
        }
    };

    return (
        <div className="p-6">
            {/* Header with Add Student Button */}

            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Students</h1>
                {userRole === "school" && (
                    <div className="flex justify-between space-x-4 items-center">
                        <button
                            onClick={() => openModal()}
                            className="p-2 bg-blue-500 text-white rounded"
                        >
                            Add Student
                        </button>
                        <div className="flex justify-between items-center">
                            <label className={`bg-blue-500 text-white px-4 py-2 rounded-md ${uploading ? 'cursor-not-allowed bg-gray-500' : 'hover:bg-blue-600'}  transition flex items-center space-x-2 cursor-pointer`}>
                                <FontAwesomeIcon icon={faUpload} />
                                <span>Upload Audio</span>
                                <input
                                    disabled={uploading}
                                    type="file"
                                    name="file"
                                    multiple={false}
                                    // accept=".xls, .xlsx"
                                    accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileUpload}
                                    className="hidden"
                                />
                            </label>
                        </div>
                    </div>
                )}

            </div>
            <Table columns={columns} data={students} onRowClick={(row) => {
                console.log(row);
                openModal(row);
            }} />

            {/* Modal Component */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="medium"
            >
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block mb-2">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={student.firstName}
                                onChange={handleInputChange}
                                className="w-full border p-2 rounded"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={student.lastName}
                                onChange={handleInputChange}
                                className="w-full border p-2 rounded"
                                required
                            />
                        </div>
                        {/* <div className="mb-4">
                            <label className="block mb-2">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={student.email}
                                onChange={handleInputChange}
                                className="w-full border p-2 rounded"
                                required
                            />
                        </div> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

                            <div className="mb-4">
                                <label className="block mb-2">Class</label>
                                <select name="class" value={student.class} onChange={handleInputChange} className="w-full border p-2 rounded">
                                    <option value="class">Class</option>
                                    {CLASS_OPTIONS.map((c) => <option key={c} value={c}>{c}</option>)}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Section</label>
                                <select name="section" value={student.section} onChange={handleInputChange} className="w-full border p-2 rounded">
                                    <option value="section">Section</option>
                                    {SECTION_OPTIONS.map((c) => <option key={c} value={c}>{c}</option>)}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Roll No.</label>
                                <input type="number" name="rollNumber" value={student.rollNumber} onChange={handleInputChange} className="w-full border p-2 rounded" required />
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2">Phone Number</label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    value={student.phoneNumber}
                                    onChange={handleInputChange}
                                    className="w-full border p-2 rounded"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                disabled={isAddingStudent || isUpdatingStudent}
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                                onClick={() => closeModal()}
                            >
                                Close
                            </button>
                            <button
                                disabled={isAddingStudent || isUpdatingStudent}
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                            >
                                {student.id ? "Update" : "Add"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>

        </div >
    );
};

export default Students;
