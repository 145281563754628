import React, { useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";

interface VoiceRecorderProps {
  onSubmit: (file: File) => void
  isInProgress: boolean
}

const VoiceRecorder: React.FC<VoiceRecorderProps> = ({onSubmit, isInProgress}) => {
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const [audioBlob, setAudioBlob] = useState<Blob | null>()
  // pauseRecording: () => void;
  // resumeRecording: () => void;
  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
    previewAudioStream,
    status,
    clearBlobUrl: reset,
  } = useReactMediaRecorder({
    audio: true,
    onStop: (b, blob) => {
      setAudioBlob(blob)
      setRecordingTime(0);
    },
  });

  const startTimer = () => {
    const interval = setInterval(() => {
      // const time = recordingTime + 1;
      setRecordingTime((prev) => prev + 1);
    }, 1000);

    setTimerInterval(interval);
  };

  const clearTimer = () => {
    if (timerInterval) clearInterval(timerInterval);
  };

  const handleStart = () => {
    startRecording();
    startTimer();
  };

  const handleStop = () => {
    stopRecording();
    clearTimer();
  };

  const handlePause = () => {
    clearTimer();
    pauseRecording();
  };

  const handleResume = () => {
    startTimer();
    resumeRecording();
  };

  const handleReset = () => {
    reset();
    setRecordingTime(0);
    clearTimer();
  };

  const handleSendRecording = () => {
    if (mediaBlobUrl) {
      // handle file upload here
      console.log("Send audio file:", mediaBlobUrl);
      sendRecording()
    }
  };

  const convertToWav = async (aBlob: Blob) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await aBlob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Convert to WAV
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numberOfChannels * 2;
    const buffer = new ArrayBuffer(44 + length);
    const view = new DataView(buffer);

    // Write WAV header
    const writeString = (view: any, offset: any, string: any) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, audioBuffer.sampleRate, true);
    view.setUint32(28, audioBuffer.sampleRate * 2, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    // Write audio data
    const offset = 44;
    const channelData = [];
    for (let i = 0; i < numberOfChannels; i++) {
      channelData.push(audioBuffer.getChannelData(i));
    }

    let index = 0;
    while (index < audioBuffer.length) {
      for (let i = 0; i < numberOfChannels; i++) {
        const sample = channelData[i][index] * 0x7fff;
        view.setInt16(offset + (index * numberOfChannels + i) * 2, sample, true);
      }
      index++;
    }

    return new Blob([buffer], { type: 'audio/wav' });
  }

  const sendRecording = async () => {
    try {
      // this.sendBtn.disabled = true;
      // this.showStatus('Converting to WAV format...');

      // Convert to WAV
      if (!audioBlob) return
      const wavBlob = await convertToWav(audioBlob);

      const audioFile = audioUtils.audioBlobToFile(wavBlob, 'testne');
      onSubmit(audioFile)

      // this.showStatus('Sending recording...');

      // Create FormData and append the WAV file
      // const formData = new FormData();
      // formData.append('audio', wavBlob, 'recording.wav');

      // // Send to API
      // const response = await fetch('YOUR_API_ENDPOINT', {
      //   method: 'POST',
      //   body: formData
      // });

      // if (response.ok) {
      //   // this.showStatus('Recording sent successfully!', 'success');
      // } else {
      //   throw new Error(`Server responded with ${response.status}`);
      // }
    } catch (error) {
      console.error('Error sending recording:', error);
      // this.showStatus(`Error sending recording: ${error.message}`, 'error');
      // this.sendBtn.disabled = false;
    }
  }

  // Base blob to file converter
  const blobToFile = (blob: Blob, fileName: string, mimeType = blob.type) => {
    const options = {
      lastModified: new Date().getTime(),
      type: mimeType
    };
    return new File([blob], fileName, options);
  };

  const audioUtils = {
    // Convert audio blob to file with proper extension
    audioBlobToFile: (audioBlob: Blob, fileName: string) => {
      // Map of common audio MIME types to extensions
      const mimeToExt: any = {
        'audio/wav': '.wav',
        'audio/mpeg': '.mp3',
        'audio/mp4': '.m4a',
        'audio/webm': '.webm',
        'audio/ogg': '.ogg'
      };

      // Get the file extension based on MIME type or default to .wav
      const ext = mimeToExt[audioBlob.type] || '.wav';
      // Add extension if filename doesn't have it
      const fullFileName = fileName.endsWith(ext) ? fileName : `${fileName}${ext}`;

      return blobToFile(audioBlob, fullFileName, audioBlob.type);
    },
    // Create an audio element and play the blob/file
    createAudioPreview: (blobOrFile: any) => {
      const audio = new Audio();
      audio.src = URL.createObjectURL(blobOrFile);
      return audio;
    },

    // Get audio duration (returns promise)
    getAudioDuration: (audioFile: any) => {
      return new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.onloadedmetadata = () => resolve(audio.duration);
        audio.onerror = reject;
        audio.src = URL.createObjectURL(audioFile);
      });
    }
  }


  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 py-2">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <div className="text-3xl font-mono text-center mb-4">
          {formatTime(recordingTime)}
        </div>
        <div className="flex justify-around mb-4">
          {status === "idle" && (
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleStart}
            >
              Start
            </button>
          )}
          {status === "recording" && (
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={handlePause}
            >
              Pause
            </button>
          )}
          {status === "paused" && (
            <button
              className="bg-yellow-500 text-white px-4 py-2 rounded-md"
              onClick={handleResume}
            >
              Resume
            </button>
          )}
          {(status === "recording" || status === "paused") &&
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleStop}
            >
              Stop
            </button>}
        </div>

        {/* Styled Audio Player */}
        {mediaBlobUrl && (
          <div className="flex flex-col items-center mb-4">
            <audio
              className="w-full h-12 bg-gray-200 rounded-lg"
              src={mediaBlobUrl}
              controls
            />
          </div>
        )}
        {status === "stopped" && mediaBlobUrl && <>
          <button
            className="bg-green-500 text-white w-full py-2 rounded-md"
            onClick={handleSendRecording}
            disabled={isInProgress}
          >
            Submit
          </button>
          <button
            className="bg-red-500 text-white w-full py-2 mt-2 rounded-md"
            onClick={handleReset}
            disabled={isInProgress}
          >
            Reset
          </button> </>}
      </div>
    </div>
  );
};

export default VoiceRecorder;
