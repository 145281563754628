import { getDataWithQueryParams, getData, postData, putData, deleteData, fileUpload } from './api';

export interface IExam {
    id?: string;
    title: string;
    type: string;
    topic: string;
    duration: number;
    class: number;
    description?: string;
    section?: string;
    isPractice?: boolean;
}

export const getExams = async (data: any) => {
    return await getDataWithQueryParams(`/exams`, data);;
};

export const getExam = async (id: string) => {
    return await getData(`/exams/${id}`, id);
};

export const addExam = async (data: IExam) => {
    return await postData('/exams', data);
};

export const updateExam = async (id: number, data: IExam) => {
    return await putData(`/exams/${id}`, data);
};

export const deleteExam = async (id: string) => {
    return await deleteData(`/exams/`, id);
};

export const takenExam = async (id:string, studentId:string, data: any, config: any) => {
    return await fileUpload(`/exams/${id}/submit/${studentId}`, data, config);
};

export const getExamReport = async (queryObject: Record<string, any>, queryString: string) => {
    return await getDataWithQueryParams(`/exams/report/all`, queryObject);;
};
