import { useEffect, useState } from 'react';
import { IApiResponse } from '../interfaces';

interface useApiOptionsProps {
    enabled?: boolean;
    onSuccess?: (res: IApiResponse) => any;
    onError?: (error: Error) => any;
    onFire?: () => void;
}

export const useApi = (
    key: string | string[],
    fn: (data?: any) => Promise<IApiResponse>,
    options?: useApiOptionsProps
) => {
    const {
        onSuccess = (res: IApiResponse) => res,
        onError = (error: any) => error,
        onFire = () => null,
        enabled = true
    } = options || {};
    const [isLoading, setIsLoading] = useState(true);
    const [isError, seIsError] = useState(false);
    const [data, setData] = useState();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [tablePagination, setTablePagination] = useState({
        showPagination: false,
        count: 1,
        page: 1,
        limit: 10
    });

    const handlePageChange = (pageNumber: number) => {
        setTablePagination((prevTablePagination) => ({
            ...prevTablePagination,
            page: pageNumber
        }));
    };

    const handleLimitChange = (pageLimit: number) => {
        setTablePagination((prevTablePagination) => ({
            ...prevTablePagination,
            limit: pageLimit
        }));
    };

    const updatePagination = (data: any) => {
        setTablePagination((prevTablePagination) => ({
            ...prevTablePagination,
            ...data
        }));
    };

    const callFn = async (data?: any) => {
        try {
            setIsLoading(true);
            onFire();
            const apiRes: Awaited<IApiResponse> = await fn(data);
            if (apiRes.status === 200) {
                const resData = apiRes.data;
                if (resData.Items) {
                    updatePagination({
                        page: resData.CurrentPage,
                        count: resData.TotalItems,
                        limit: resData.ItemsPerPage
                    });
                }
                const tempData = onSuccess(apiRes);
                if (tempData.data && tempData.data.Items) {
                    setData(tempData.data.Items);
                } else if (tempData.Items) {
                    setData(tempData.Items);
                } else {
                    setData(tempData);
                }
                // setData(tempData.Items ? tempData.Items : tempData);
            } else {
                throw new Error(apiRes.error);
            }
        } catch (error: any) {
            console.error(error);
            seIsError(true);
            onError(error);
            setErrorMessage(error.message ? error.message : error);
        } finally {
            setIsLoading(false);
        }
    };

    const apiCall = (data?: any) => {
        callFn(data);
    };

    useEffect(() => {
        if (enabled) {
            callFn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    return {
        data,
        fn,
        refetch: apiCall,
        errorMessage,
        isLoading,
        isError,
        pagination: {
            ...tablePagination,
            handleLimitChange: handleLimitChange,
            handlePageChange: handlePageChange
        }
    };
};

// export const useApi = () => {
//     return {};
// };


