import React from 'react';

interface CircularProgressProps {
  /** Percentage value between 0 and 100 */
  percentage?: number;
  /** Title displayed above the progress circle */
  title?: string;
  /** Optional custom class names for the container */
  className?: string;
  /** Optional size override for the circle (default: 32 - 8rem) */
  size?: 'sm' | 'md' | 'lg' | 'xl';
  /** Optional color override for the progress bar */
  color?: 'blue' | 'green' | 'red' | 'yellow' | 'purple';
}

const sizeClasses = {
  sm: 'w-24 h-24',
  md: 'w-32 h-32',
  lg: 'w-40 h-40',
  xl: 'w-48 h-48'
};

const colorClasses = {
  blue: 'stroke-blue-600',
  green: 'stroke-green-600',
  red: 'stroke-red-600',
  yellow: 'stroke-yellow-600',
  purple: 'stroke-purple-600'
};

const CircularProgress: React.FC<CircularProgressProps> = ({ 
  percentage = 75, 
  title = "Progress",
  className = "",
  size = 'md',
  color = 'blue'
}) => {
  // Ensure percentage is between 0 and 100
  const normalizedPercentage = Math.min(100, Math.max(0, percentage));
  
  // Calculate the circle's circumference and stroke-dasharray
  const radius = size === 'sm' ? 35 : size === 'md' ? 40 : size === 'lg' ? 45 : 50;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = circumference;
  const strokeDashoffset = circumference - (normalizedPercentage / 100) * circumference;

  return (
    <div className={`flex flex-col items-center gap-2 ${className}`}>
      {/* Title */}
      <h3 className="text-lg font-medium text-gray-700">{title}</h3>
      
      {/* Progress Circle */}
      <div className="relative inline-flex items-center justify-center">
        <svg className={`transform -rotate-90 ${sizeClasses[size]}`}>
          {/* Background circle */}
          <circle
            cx={radius + 24}
            cy={radius + 24}
            r={radius}
            className="stroke-gray-200"
            strokeWidth="8"
            fill="none"
          />
          {/* Progress circle */}
          <circle
            cx={radius + 24}
            cy={radius + 24}
            r={radius}
            className={colorClasses[color]}
            strokeWidth="8"
            fill="none"
            style={{
              strokeDasharray,
              strokeDashoffset,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease'
            }}
          />
        </svg>
        {/* Percentage text */}
        <span className={`absolute text-2xl font-semibold ${color === 'yellow' ? 'text-yellow-600' : colorClasses[color].replace('stroke-', 'text-')}`}>
          {normalizedPercentage}%
        </span>
      </div>
    </div>
  );
};

// Example usage showing different variations
// const CircularProgressDemo: React.FC = () => {
//   return (
//     <div className="flex flex-wrap gap-8 p-8 bg-white rounded-lg shadow-sm">
//       <CircularProgress 
//         percentage={25} 
//         title="Q1 Goals" 
//         size="sm"
//         color="red"
//       />
//       <CircularProgress 
//         percentage={50} 
//         title="Projects" 
//         size="md"
//         color="blue"
//       />
//       <CircularProgress 
//         percentage={75} 
//         title="Tasks" 
//         size="lg"
//         color="green"
//       />
//       <CircularProgress 
//         percentage={100} 
//         title="Completed" 
//         size="xl"
//         color="purple"
//       />
//     </div>
//   );
// };

export default CircularProgress;