// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
// import UsersList from './pages/Users/UsersList';
import Students from "./pages/Students";
import PrivateRoute from './components/PrivateRoute';
import Schools from "./pages/Schools";
import CreateExam from "./pages/Exams/CreateExam";
import TakeExam from "./pages/Exams/TakeExam";
import 'react-toastify/dist/ReactToastify.css';
import Exams from './pages/Exams/Exams';
import Reports from './pages/Reports';

const App: React.FC = () => {
    console.log('App');
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                    <Route path="/schools" element={<PrivateRoute element={<Schools key="Schools" />} />} />
                    <Route path="/students" element={<PrivateRoute element={<Students />} />} />
                    <Route
                        path="practice/create-practice"
                        element={<PrivateRoute element={<CreateExam isPractice={true} />} />}
                    // element={<CreateExam setExams={setExams} exams={exams} />}  // Pass exams and setExams to the component
                    />
                    <Route
                        path="practice/take-practice"
                        element={<PrivateRoute element={<Exams isPractice={true} />} />}
                    // element={<TakeExam exams={exams} />}  // Pass exams to TakeExam component
                    />
                    <Route
                        path="practice/take-practice/:id"
                        element={<PrivateRoute element={<TakeExam isPractice={true} />} />}
                    // element={<TakeExam exams={exams} />}  // Pass exams to TakeExam component
                    />
                    <Route
                        path="exams/create-exam"
                        element={<PrivateRoute element={<CreateExam isPractice={false} />} />}
                    // element={<CreateExam setExams={setExams} exams={exams} />}  // Pass exams and setExams to the component
                    />
                    <Route
                        path="exams/take-exam"
                        element={<PrivateRoute element={<Exams isPractice={false} />} />}
                    // element={<TakeExam exams={exams} />}  // Pass exams to TakeExam component
                    />
                    <Route
                        path="exams/take-exam/:id"
                        element={<PrivateRoute element={<TakeExam isPractice={false} />} />}
                    // element={<TakeExam exams={exams} />}  // Pass exams to TakeExam component
                    />
                    <Route
                        path="reports"
                        element={<PrivateRoute element={<Reports isPractice={false} />} />}
                    // element={<TakeExam exams={exams} />}  // Pass exams to TakeExam component
                    />
                    
                    <Route path="*" element={<Login />} />
                    {/* Add more routes here as needed */}
                </Routes>
            </Router>
            <ToastContainer />
        </AuthProvider>
    );
};

export default App;
