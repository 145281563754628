import { useState, useEffect, useRef } from 'react';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options: Option[];
  value?: Option | Option[] | null;
  onChange: (value: Option | Option[] | null) => void;
  placeholder?: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  className?: string;
  isDisabled?: boolean;
  menuPlacement?: 'top' | 'bottom';
  maxMenuHeight?: number;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ 
  options = [], 
  value = null,
  onChange = () => {},
  placeholder = "Select an option...",
  isMulti = false,
  isSearchable = true,
  className = "",
  isDisabled = false,
  menuPlacement = 'bottom',
  maxMenuHeight = 300
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<Option[] | Option | null>(isMulti ? [] : null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("useEffect value", value);
    if ((value !== undefined && value !== null) && value !== selectedOptions) {

      setSelectedOptions(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (option: Option) => {
    if (isDisabled) return;

    if (isMulti) {
      const currentSelected = selectedOptions as Option[];
      const newValue = currentSelected.some(item => item.value === option.value)
        ? currentSelected.filter(item => item.value !== option.value)
        : [...currentSelected, option];
      setSelectedOptions(newValue);
      onChange(newValue);
    } else {
      setSelectedOptions(option);
      onChange(option);
      setIsOpen(false);
    }
    setSearchTerm("");
  };

  const displayValue = (): string => {
    if (!selectedOptions) return placeholder;
    if (isMulti) {
      const selected = selectedOptions as Option[];
      return selected.length > 0
        ? selected.map(option => option.label).join(", ")
        : placeholder;
    }
    return (selectedOptions as Option).label;
  };

  const getMenuPosition = (): string => {
    if (menuPlacement === 'top') {
      return 'bottom-full mb-1';
    }
    return 'top-full mt-1';
  };

  return (
    <div className={`relative w-full ${className}`} ref={containerRef}>
      <div
        className={`w-full p-2.5 border hover:border-2 rounded-md bg-white flex items-center justify-between transition-all
          ${isDisabled 
            ? 'bg-gray-50 border-gray-200 cursor-not-allowed text-gray-400' 
            : 'cursor-pointer hover:border-gray-500 border-gray-300 text-gray-900'
          }
          ${isOpen ? 'ring-4 ring-gray-100 border-gray-500' : ''}
        `}
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-disabled={isDisabled}
      >
        <div className="flex-grow truncate">
          {displayValue()}
        </div>
        <div className={`transform transition-transform ${isOpen ? 'rotate-180' : ''} ml-2 text-gray-400`}>
          ▼
        </div>
      </div>

      {isOpen && !isDisabled && (
        <div 
          className={`absolute w-full bg-white border hover:border-2 border-gray-300 rounded-md shadow-lg z-10 ${getMenuPosition()}`}
          style={{ maxHeight: `${maxMenuHeight}px` }}
          role="listbox"
        >
          {isSearchable && (
            <div className="p-2 border-b-2 border-gray-200 sticky top-0 bg-white">
              <input
                type="text"
                className="w-full p-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-4 focus:ring-gray-100 focus:border-gray-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                placeholder="Search..."
                aria-label="Search options"
              />
            </div>
          )}
          
          <div className="overflow-auto" style={{ maxHeight: `${maxMenuHeight - (isSearchable ? 57 : 0)}px` }}>
            {filteredOptions.map((option) => {
              const isSelected = isMulti
                ? (selectedOptions as Option[]).some(item => item.value === option.value)
                : (selectedOptions as Option)?.value === option.value;

              return (
                <div
                  key={option.value}
                  className={`p-2.5 cursor-pointer transition-colors border-l-4
                    ${isSelected 
                      ? 'bg-gray-100 text-gray-900 border-l-gray-500' 
                      : 'text-gray-700 hover:bg-gray-50 border-l-transparent hover:border-l-gray-300'
                    }
                  `}
                  onClick={() => handleSelect(option)}
                  role="option"
                  aria-selected={isSelected}
                >
                  {isMulti && (
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => {}}
                      className="mr-2 h-4 w-4 rounded border-2 border-gray-400 text-gray-600 focus:ring-4 focus:ring-gray-100"
                    />
                  )}
                  {option.label}
                </div>
              );
            })}
            
            {filteredOptions.length === 0 && (
              <div className="p-2.5 text-gray-400 text-center">
                No options found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;