import React, { useState, useEffect } from 'react';
import { useToast } from "../../hooks";
import { useApi } from "../../hooks/useNew";
import { getExams, getExamReport } from "../../services/examService";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from 'react-router-dom';
import { IExam } from '../../interfaces';
import { humanReadableDate } from '../../utils';
import DataTable, { Column } from '../../components/DataTable';


// const columns: ColumnDef<IExam>[] = [
//     {
//         accessorKey: "title",
//         header: "Exam Name",
//         cell: (info) => {
//             const originalData = info?.row?.original || {};
//             return (
//                 <Link to={`/exams/take-exam/${originalData.id}`} className="flex items-center text-sky-500">
//                     {originalData.title}
//                 </Link>
//             );
//         }
//     },

//     {
//         accessorKey: "type",
//         header: "Type",
//     },
//     {
//         accessorKey: "date",
//         header: "Created Date",
//         cell: (info) => {
//             const originalData = info?.row?.original || {};
//             return humanReadableDate(originalData.createdAt);
//         }
//     },
//     {
//         accessorKey: "class",
//         header: "Class",
//     },
//     {
//         accessorKey: "section",
//         header: "Section",
//     },
//     {
//         accessorKey: "totalStudents",
//         header: "Total Students"
//     },
//     {
//         accessorKey: "totalStudentsTakeExam",
//         header: "Total Take Exam"
//     }
// ];

interface User {
    id: number;
    name: string;
    email: string;
    status: string;
}


const Reports: React.FC<{ isPractice?: boolean }> = ({ isPractice = false }) => {
    console.log("Exams isPractice", isPractice)
    const { showToast, updateToast, dismissToast, } = useToast();
    const {
        data: exams = [],
        isLoading: isFetchingExams,
        refetch: fetchExams,
        pagination,
    } = useApi<IExam[]>(
        "ExamList",
        () =>
            getExams({
                query: "",
                pageNumber: 1,
                pageSize: 100,
                isPractice: isPractice
            }),
        {
            enabled: false,
            onSuccess: (response: any) => {
                console.log(response);
                return response;
            },
            onError: (error: Error) => {
                showToast("error", error.message);
            },
        },
    );

    useEffect(() => {
        // fetchExams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPractice]);

    const columns: Column[] = [
        // {
        //     field: 'id',
        //     header: 'ID',
        //     sortable: true,
        //     filterable: true,
        //     frozen: true,
        // },
        {
            field: 'title',
            header: 'Name',
            sortable: true,
            filterable: true,
            editable: true,
            frozen: true,
        },
        {
            field: 'type',
            header: 'Type',
            sortable: true,
            filterable: true,
            editable: true,
        },
        {
            field: 'class',
            header: 'Class',
            sortable: true,
            filterable: true,
            editable: true,
        },
        {
            field: 'section',
            header: 'Section',
            sortable: true,
            filterable: true,
            editable: true,
        },
    ];

    const addFieldInRootObject = (obj: any, rootObject: object = {}, prefix = "root_", ignoreKeys:Array<string> = [], deep = false): object => {
        const updatedObject:any = {
            ...rootObject,
        }
        Object.keys(obj).forEach((key: any) => {
            if (!ignoreKeys.includes(key)) {
                // Add root data to the student, with prefix if the key already exists
                if((deep && obj[key])&& typeof obj[key] === 'object'){
          console.log("key", key, obj[key])

                    const newKey = updatedObject.hasOwnProperty(key) ? `${prefix}${key}` : key;
                    updatedObject[newKey] = addFieldInRootObject(obj[key], updatedObject, key+"_", ignoreKeys, deep)
                } else {
                    const newKey = updatedObject.hasOwnProperty(key) ? `${prefix}${key}` : key;
                    updatedObject[newKey] = obj[key];
                }
                
            }
        });
        return updatedObject;
    }

    const addRootDataToAllStudents = (rootArray: any[], prefix = "root_"): any[] => {
        const studentList:Array<any> = [];
        const newData = rootArray.map((rootItem) => {
            // Map through each student in the current rootItem
            rootItem.students = rootItem.students?.map((student:any) => {
                if(student.report){
                    delete student.report.apiResponse
                }
                const updatedStudent:any = addFieldInRootObject(student, rootItem, "", ['apiResponse'], true);

                delete updatedStudent.students
    
                // Loop through each key in the root item except 'students'
                // Object.keys(rootItem).forEach((key) => {
                //     if (key !== "students") {
                //         // Add root data to the student, with prefix if the key already exists
                //         const newKey = updatedStudent.hasOwnProperty(key) ? `${prefix}${key}` : key;
                //         updatedStudent[newKey] = rootItem[key];
                //     }
                // });

                studentList.push(updatedStudent)
    
                return updatedStudent;
            });
    
            return rootItem;
        });

        console.log('studentList', studentList)

        return newData
    }

    const fetchData = (params:any): Promise<{total: number, data:any}> =>
        new Promise(async (resolve, reject) => {
            console.log("fetchData params: ", params)
            const respo = await getExamReport({
                query: params.search || "",
                pageNumber: params.pageNumber,
                pageSize: params.pageSize,
               ...params
                // isPractice: isPractice
            }, "")
            console.log('respo', respo)
            if (respo.status === 200) {
                addRootDataToAllStudents(respo.data.items || [])
                resolve({
                    total: respo.data.totalItems || 1,
                    data: respo.data.items || []
                })
            } else {
                reject("Error")
            }
        });


    return (
        <div className="p-4 m-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-2xl font-semibold mb-4">{isPractice ? 'Practice' : 'Take'} Exam</h1>
            {/* <Table columns={columns} data={exams} onRowClick={(row) => {
                console.log(row);
            }} /> */}
            <DataTable<User>
                fetchData={fetchData} // TODO: fetchExams()}
                onSave={() => Promise.resolve()}
                onExport={() => Promise.resolve()}
                initialColumns={columns}
                initialPageSize={10}
            />
        </div>
    );
};

export default Reports;
