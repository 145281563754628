import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    size?: 'small' | 'medium' | 'large';
    children: ReactNode;
    footer?: ReactNode;
    header?: ReactNode;
}

const MODAL_SIZES = {
    small: 'max-w-md min-w-[300px]',  // Small Modal with a min width
    medium: 'max-w-xl min-w-[500px]', // Medium Modal with a min width
    large: 'max-w-4xl min-w-[700px]', // Large Modal with a min width
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, size = 'medium', children, footer, header }) => {
    const [showModal, setShowModal] = useState(isOpen);

    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
        } else {
            const timer = setTimeout(() => setShowModal(false), 300); // Delay closing for exit animation
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    console.log('showModal', showModal);

    if (!showModal) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            {/* Modal Content */}
            <div
                className={clsx(
                    'bg-white rounded-lg shadow-lg p-6 relative z-10', // Ensure modal is above overlay
                    'transform transition-transform duration-300',
                    isOpen ? 'opacity-100 translate-y-0 scale-100' : 'opacity-0 translate-y-10 scale-95',
                    MODAL_SIZES[size]
                )}
            >
                {/* Modal Header */}
                {header && (
                    <div className="mb-4 border-b pb-2">
                        {header}
                    </div>
                )}

                {/* Modal Body Content */}
                <div className="mb-4">
                    {children}
                </div>

                {/* Modal Footer */}
                {footer && (
                    <div className="mt-4 border-t pt-4">
                        {footer}
                    </div>
                )}

                {/* Close Button */}
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Modal;
