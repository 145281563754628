import React, { useState, useEffect, useCallback } from "react";
import { Download, Eye, EyeOff, ChevronDown, ChevronUp, Edit2, Save, X } from 'lucide-react';

// const debounce = (func: (...args: any[]) => void, delay: number, immediate = false) => {
//   let timeoutId: NodeJS.Timeout | null;

//   return (...args: any[]) => {
//     const callNow = immediate && !timeoutId;
//     if (timeoutId) clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       timeoutId = null;
//       if (!immediate) func(...args);
//     }, delay);

//     if (callNow) func(...args);
//   };
// }

// function useDebounce(callback: (...args: any[]) => void, delay: number) {
//   const debouncedCallback = useCallback(callback, [callback]);

//   useEffect(() => {
//     const handler = setTimeout(() => {
//       debouncedCallback();
//     }, delay);

//     return () => {
//       clearTimeout(handler);
//     };
//   }, [debouncedCallback, delay]);
// }

function debounce(fn: Function, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn(...args), delay);
  };
}


// const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   const debouncedFunction = useCallback(debounce(callback, delay), [callback, delay]);
//   return debouncedFunction;
// };

// Types
export interface Column {
    field: string;
    header: string;
    sortable?: boolean;
    filterable?: boolean;
    editable?: boolean;
    frozen?: boolean;
    visible?: boolean;
  }
  
  interface DataTableProps<T> {
    fetchData: (params: FetchParams) => Promise<FetchResponse<T>>;
    onSave: (data: T) => Promise<void>;
    onExport: (format: ExportFormat) => Promise<void>;
    initialColumns: Column[];
    initialPageSize?: number;
  }
  
  interface FetchParams {
    pageNumber: number;
    pageSize: number;
    sortField: string | null;
    sortOrder: SortOrder;
    search: string;
    filters: Record<string, string>;
  }
  
  interface FetchResponse<T> {
    data: T[];
    total: number;
  }
  
  type SortOrder = 'asc' | 'desc';
  type ExportFormat = 'pdf' | 'csv' | 'xlsx';
  
  interface EditingCell {
    rowIndex: number | null;
    column: Column | null;
  }
  
  interface ButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    variant?: 'primary' | 'secondary' | 'ghost';
    size?: 'sm' | 'md' | 'lg';
    className?: string;
    disabled?: boolean;
  }
  
  // Button Component
  const Button: React.FC<ButtonProps> = ({ 
    children, 
    onClick, 
    variant = 'primary', 
    size = 'md', 
    className = '',
    disabled = false 
  }) => {
    const baseStyles = 'rounded font-medium transition-colors';
    const variants = {
      primary: 'bg-blue-500 hover:bg-blue-600 text-white disabled:bg-blue-300',
      secondary: 'bg-gray-200 hover:bg-gray-300 text-gray-800 disabled:bg-gray-100',
      ghost: 'hover:bg-gray-100 disabled:hover:bg-transparent',
    };
    const sizes = {
      sm: 'px-2 py-1 text-sm',
      md: 'px-4 py-2',
      lg: 'px-6 py-3 text-lg',
    };
  
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
      >
        {children}
      </button>
    );
  };
  
  // Main DataTable Component
  const DataTable = <T extends Record<string, any>>({ 
    fetchData,
    onSave,
    onExport,
    initialColumns,
    initialPageSize = 10,
  }: DataTableProps<T>): React.ReactElement => {
    // State management
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [expandedRows, setExpandedRows] = useState<Record<string | number, boolean>>({});
    const [editingCell, setEditingCell] = useState<EditingCell>({ rowIndex: null, column: null });
    const [editingRow, setEditingRow] = useState<number | null>(null);
    const [editBuffer, setEditBuffer] = useState<Record<string, any>>({});
    const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>(
      initialColumns.reduce((acc, col) => ({ ...acc, [col.field]: true }), {})
    );
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    
    // Sorting state
    const [sortField, setSortField] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
    
    // Filter and search state
    const [filters, setFilters] = useState<Record<string, string>>({});
    const [searchQuery, setSearchQuery] = useState<string>('');
  
    // Load data when parameters change
    useEffect(() => {
      console.log("useEffect loadData => ")
      loadData();
    }, [currentPage, pageSize, sortField, sortOrder, searchQuery, filters]);
  
    const loadData = async (): Promise<void> => {
      setLoading(true);
      try {
        const params: FetchParams = {
          pageNumber: currentPage,
          pageSize,
          sortField,
          sortOrder,
          search: searchQuery,
          filters,
        };
        
        const result = await fetchData(params);
        setData(result.data);
        setTotalRecords(result.total);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
      // debouncedFiltersAndSearch();
    };

  //   const debouncedFiltersAndSearch = useCallback(
  //     debounce(() => {
  //       console.log("debouncedFiltersAndSearch fire => loadData", filters)
  //       loadData();
  //     }, 300), // 300ms debounce time
  //     [] // You can customize debounce delay as needed
  // );
  
    // Row expansion toggle
    const toggleRowExpansion = (rowId: string | number): void => {
      setExpandedRows(prev => ({ ...prev, [rowId]: !prev[rowId] }));
    };
  
    // Edit mode handlers
    const startCellEdit = (rowIndex: number, column: Column): void => {
      setEditingCell({ rowIndex, column });
      setEditBuffer({ 
        ...editBuffer, 
        [`${rowIndex}-${column.field}`]: data[rowIndex][column.field] 
      });
    };
  
    const startRowEdit = (rowIndex: number): void => {
      setEditingRow(rowIndex);
      setEditBuffer({ ...data[rowIndex] });
    };
  
    const handleCellEdit = async (rowIndex: number, column: Column): Promise<void> => {
      const newValue = editBuffer[`${rowIndex}-${column.field}`];
      const updatedRow = { 
        ...data[rowIndex], 
        [column.field]: newValue 
      };
      
      try {
        await onSave(updatedRow);
        const newData = [...data];
        newData[rowIndex] = updatedRow;
        setData(newData);
        setEditingCell({ rowIndex: null, column: null });
      } catch (error) {
        console.error('Error saving cell:', error);
      }
    };
  
    const handleRowEdit = async (rowIndex: number): Promise<void> => {
      try {
        await onSave(editBuffer as T);
        const newData = [...data];
        newData[rowIndex] = editBuffer as T;
        setData(newData);
        setEditingRow(null);
      } catch (error) {
        console.error('Error saving row:', error);
      }
    };
  
    // Sorting handler
    const handleSort = (column: Column): void => {
      if (!column.sortable) return;
      
      const newOrder: SortOrder = sortField === column.field && sortOrder === 'asc' ? 'desc' : 'asc';
      setSortField(column.field);
      setSortOrder(newOrder);
    };

    const handleChangePageNumber = (pNumber: number | string) => {
      setCurrentPage(+pNumber)
      loadData()
    }
  
    // Filter handler
    const handleFilter = (column: Column, value: string): void => {
      setFilters(prev => ({
        ...prev,
        [column.field]: value
      }));
      setCurrentPage(1);
      // handleChangePageNumber(1);
      // debouncedFiltersAndSearch()
    };
  
    // Column visibility toggle
    const toggleColumnVisibility = (columnField: string): void => {
      setVisibleColumns(prev => ({
        ...prev,
        [columnField]: !prev[columnField]
      }));
    };
  
    // Render table header
    const renderHeader = (column: Column): React.ReactNode => {
      if (!visibleColumns[column.field]) return null;
  
      return (
        <th 
          key={column.field}
          className={`p-3 text-left bg-gray-100 ${column.frozen ? 'sticky left-0 z-10 bg-gray-100' : ''}`}
        >
          <div className="flex items-center gap-2">
            <span>{column.header}</span>
            {column.sortable && (
              <button
                onClick={() => handleSort(column)}
                className="p-1 hover:bg-gray-200 rounded"
              >
                {sortField === column.field ? (
                  sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                ) : (
                  <ChevronDown size={16} className="text-gray-400" />
                )}
              </button>
            )}
          </div>
          {column.filterable && (
            <input
              type="text"
              placeholder={`Filter ${column.header}`}
              className="mt-2 w-full p-1 text-sm border rounded"
              onChange={(e) => handleFilter(column, e.target.value)}
            />
          )}
        </th>
      );
    };
  
    // Render table cell
    const renderCell = (rowData: T, column: Column, rowIndex: number): React.ReactNode => {
      if (!visibleColumns[column.field]) return null;
  
      if (editingCell.rowIndex === rowIndex && editingCell.column?.field === column.field) {
        return (
          <td key={column.field} className="p-3 border-b">
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={editBuffer[`${rowIndex}-${column.field}`] || ''}
                onChange={(e) => setEditBuffer({
                  ...editBuffer,
                  [`${rowIndex}-${column.field}`]: e.target.value
                })}
                className="w-full p-1 border rounded"
                autoFocus
              />
              <Button 
                variant="ghost" 
                size="sm"
                onClick={() => handleCellEdit(rowIndex, column)}
              >
                <Save size={16} />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setEditingCell({ rowIndex: null, column: null })}
              >
                <X size={16} />
              </Button>
            </div>
          </td>
        );
      }
  
      return (
        <td 
          key={column.field}
          className={`p-3 border-b ${column.frozen ? 'sticky left-0 z-10 bg-white' : ''}`}
          onClick={() => column.editable && startCellEdit(rowIndex, column)}
        >
          {rowData[column.field]}
        </td>
      );
    };
  
    // Render expanded content
    const renderExpandedContent = (rowData: T): React.ReactNode => {
      return (
        <tr>
          <td colSpan={initialColumns.filter(col => visibleColumns[col.field]).length + 2}>
            <div className="p-4 bg-gray-50">
              <pre>{JSON.stringify(rowData, null, 2)}</pre>
            </div>
          </td>
        </tr>
      );
    };
  
    // ... Rest of the render code remains the same ...
  
    return (
      <div className="w-full">
        {/* Table controls */}
        <div className="mb-4 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleChange}
              className="p-2 border rounded"
            />
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="p-2 border rounded"
            >
              {[5, 10, 20, 50].map(size => (
                <option key={size} value={size}>
                  {size} per page
                </option>
              ))}
            </select>
          </div>
          
          <div className="flex items-center gap-2">
            {(['pdf', 'csv', 'xlsx'] as ExportFormat[]).map(format => (
              <Button key={format} onClick={() => onExport(format)}>
                <Download size={16} className="mr-2" />
                {format.toUpperCase()}
              </Button>
            ))}
          </div>
        </div>
  
        {/* Column visibility toggles */}
        <div className="mb-4 flex flex-wrap gap-2">
          {initialColumns.map(column => (
            <Button
              key={column.field}
              variant={visibleColumns[column.field] ? 'primary' : 'secondary'}
              size="sm"
              onClick={() => toggleColumnVisibility(column.field)}
            >
              {visibleColumns[column.field] ? <Eye size={16} /> : <EyeOff size={16} />}
              <span className="ml-2">{column.header}</span>
            </Button>
          ))}
        </div>
        <div className="overflow-x-auto border rounded">
        <table className="w-full">
          <thead>
            <tr>
              <th className="w-10 p-3 bg-gray-100"></th>
              {initialColumns.map(renderHeader)}
              <th className="w-10 p-3 bg-gray-100"></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td 
                  colSpan={initialColumns.filter(col => visibleColumns[col.field]).length + 2}
                  className="p-4 text-center"
                >
                  Loading...
                </td>
              </tr>
            ) : (
              data.map((rowData, rowIndex) => (
                <React.Fragment key={rowData.id}>
                  <tr className={editingRow === rowIndex ? 'bg-blue-50' : ''}>
                    <td className="p-3 border-b">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => toggleRowExpansion(rowData.id)}
                      >
                        {" "}
                        {/* {expandedRows[rowData.id] ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )} */}
                      </Button>
                    </td>
                    {initialColumns.map(column => renderCell(rowData, column, rowIndex))}
                    <td className="p-3 border-b">
                      {editingRow === rowIndex ? (
                        <div className="flex gap-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleRowEdit(rowIndex)}
                          >
                            <Save size={16} />
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => setEditingRow(null)}
                          >
                            <X size={16} />
                          </Button>
                        </div>
                      ) : (
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => startRowEdit(rowIndex)}
                        >
                          {" "}
                          {/* <Edit2 size={16} /> */}
                        </Button>
                      )}
                    </td>
                  </tr>
                  {/* {expandedRows[rowData.id] && renderExpandedContent(rowData)} */}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <span>
          Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, totalRecords)} of {totalRecords} entries
        </span>
        <div className="flex gap-2">
          <Button
            onClick={() => handleChangePageNumber(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          {/* Simple pagination numbers */}
          {[...Array(Math.ceil(totalRecords / pageSize))].map((_, i) => (
            <Button
              key={i + 1}
              variant={currentPage === i + 1 ? 'primary' : 'secondary'}
              onClick={() => handleChangePageNumber(i + 1)}
            >
              {i + 1}
            </Button>
          )).slice(Math.max(0, currentPage - 3), Math.min(currentPage + 2, Math.ceil(totalRecords / pageSize)))}
          <Button
            onClick={() => handleChangePageNumber(Math.min(Math.ceil(totalRecords / pageSize), currentPage + 1))}
            disabled={currentPage === Math.ceil(totalRecords / pageSize)}
          >
            Next
          </Button>
        </div>

  
        {/* ... Rest of the JSX remains the same ... */}
        </div>
      </div>
    );
  };
  
  export default DataTable;