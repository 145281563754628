import React, { useEffect, useState } from "react";
import AssessmentPopup from "./AssessmentPopup";
import { useToast, useUploadFile } from "../../hooks";
import { useApi } from "../../hooks/useNew";
import { getExam, takenExam } from "../../services/examService";
import { useParams } from "react-router-dom";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/react-table";
import { IExamStudent, IExam, IExamReport, IExamFile } from "../../interfaces";
import Modal from "../../components/Modal";
import CircularProgress from "../../components/CircularProgress";

const ExamReportStatus = ({ report }: { report: IExamReport }) => {
  if (report.status === "Completed") {
    return (
      <button className="bg-green-500 text-white px-6 py-1 rounded-md hover:bg-green-600 transition flex items-center space-x-2 cursor-not-allowed">
        <span>Completed</span>
      </button>
    );
  }
  if (report.status === "InProgress") {
    return (
      <button className="bg-blue-500 text-white px-6 py-1 rounded-md hover:bg-blue-600 transition flex items-center space-x-2 cursor-not-allowed">
        <span>In Progress</span>
      </button>
    );
  }
  if (report.status === "Pending") {
    return (
      <button className="bg-yellow-500 text-white px-6 py-1 rounded-md hover:bg-yellow-600 transition flex items-center space-x-2 cursor-not-allowed">
        <span>Pending</span>
      </button>
    );
  }
  if (report.status === "Error") {
    return (
      <button className="bg-red-500 text-white px-6 py-1 rounded-md hover:bg-red-600 transition flex items-center space-x-2 cursor-not-allowed">
        <span>Error</span>
      </button>
    );
  }
  return (
    <button className="bg-gray-500 text-white px-6 py-1 rounded-md hover:bg-gray-600 transition flex items-center space-x-2 cursor-not-allowed">
      <span>Not Started</span>
    </button>
  );
};

const TakeExam: React.FC<any> = ({ isPractice = false }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showResultPopup, setShowResultPopup] = useState<boolean>(false);
  const [currentStudent, setCurrentStudent] = useState<IExamStudent | null>(
    null
  );
  const [examReport, setExamReport] = useState<IExamReport | null>(null);
  const [reportFile, setReportFile] = useState<IExamFile | null>(null);
  // const [exams, setExams, clearExams] = useTempStore<Exam[]>('exams', []);
  const handleStudentClick = (student: IExamStudent) => {
    setCurrentStudent(student);
    setShowPopup(true);
  };

  const handleShowResult = (data: IExamStudent) => {
    if (!data.report) {
      return
    }
    setShowResultPopup(true);
    setCurrentStudent(data);
    setExamReport(data.report);
    setReportFile(data.file || null);
  };

  const handleResultClose = () => {
    setShowResultPopup(false);
    setCurrentStudent(null);
    setExamReport(null);
    setReportFile(null);
  };

  const columns: ColumnDef<IExamStudent>[] = [
    {
      accessorKey: "name",
      header: "Student",
      // cell: (info) => {
      //   const originalData = info?.row?.original || ({} as IExamStudent);
      //   return (
      //     <span key={originalData.id}>
      //       <span
      //         onClick={() => {
      //           if (!originalData.report) {
      //             handleStudentClick(originalData);
      //           }
      //           if (originalData.report) {
      //             handleShowResult(originalData);
      //           }
      //         }}
      //         // cursor-not-allowed
      //         className={`text-blue-500 ${!originalData.report ? "cursor-pointer hover:underline" : ""
      //           }`}
      //       >
      //         {originalData.name}
      //       </span>
      //     </span>
      //   );
      // },
    },
    {
      accessorKey: "status",
      header: "Exam Status",
      cell: (info) => {
        const originalData = info?.row?.original || {};
        return (
          <div>
            {originalData.report ? (
              <ExamReportStatus report={originalData.report} />
            ) : (
              //   <button className="bg-green-500 text-white px-6 py-1 rounded-md hover:bg-green-600 transition flex items-center space-x-2 cursor-not-allowed">
              //     <span>Completed</span>
              //   </button>
              <button
                onClick={() => handleStudentClick(originalData)}
                className="bg-green-500 text-white px-6 py-1 rounded-md hover:bg-green-600 transition flex items-center space-x-2"
              >
                <span>Start</span>
              </button>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "score",
      header: "Score",
      cell: (info) => {
        const originalData = info?.row?.original || {};
        if (originalData.report?.result?.pronunciationScore) {
          return <span>{originalData.report?.result?.pronunciationScore}</span>;
        }
        return <span>-</span>;
      },
    },
    {
      accessorKey: "remark",
      header: "Remark",
      cell: (info) => {
        const originalData = info?.row?.original || {};
        if (originalData.report?.reason) {
          return <span>{originalData.report?.reason}</span>;
        }
        return <span>-</span>;
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (info) => {
        const originalData = info?.row?.original || {};
        return (
          <div>
            {originalData.report ? (
              <button className="bg-green-500 text-white px-6 py-1 rounded-md hover:bg-green-600 transition flex items-center space-x-2" onClick={() => {
                handleShowResult(originalData);
              }}>
                <span>View</span>
              </button>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
  ];

  const { id } = useParams<{ id: string }>();

  const { showToast, updateToast, dismissToast } = useToast();
  const {
    data: exam = {} as IExam,
    isLoading: isFetchingExam,
    refetch: fetchExam,
  } = useApi<IExam>("GetExam", () => getExam(id as string), {
    enabled: false,
    onSuccess: (response: any) => {
      console.log(response);
      return response;
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  const uploadFileKey = "TakeExamFile";

  const { uploadFile, uploading, percentage } = useUploadFile(
    uploadFileKey,
    (data, config) => {
      // console.log('isFormData', isFormData(data));
      return takenExam(
        id || "fakeId",
        currentStudent?.id || "fakeId",
        data,
        config
      );
    },
    {
      onFire: () => {
        showToast("info", "Uploading 1%", uploadFileKey);
      },
      onPercentage: (val: number) => {
        updateToast("info", `Uploading ${val}%`, uploadFileKey);
      },
      onSuccess: (fileData) => {
        dismissToast(uploadFileKey);
        // console.log('res', fileData);
        setCurrentStudent(null);
        setShowPopup(false);
        showToast("success", `Successful`);
        fetchExam();
      },
      onError: (error: any) => {
        dismissToast(uploadFileKey);
        showToast("error", error.message ? error.message : error);
      },
    }
  );

  useEffect(() => {
    if (id) {
      fetchExam();
    }
  }, [id]);

  console.log("TakeExam", exam);

  const getColor = (num: number) => {
    if (num < 50) {
      return "red";
    } else if (num < 70) {
      return "yellow";
    } else {
      return "green";
    }
  }

  return (
    <div className="p-6 m-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-6">Take Exam</h1>
      <div className="space-y-4">
        {isFetchingExam && <p>Loading...</p>}
        {!isFetchingExam && !exam.id && <p>No exam found.</p>}
        <div key={exam.id} className="p-4 bg-gray-100 rounded-md shadow">
          <h2 className="text-xl font-semibold">{exam.title}</h2>
          <p className="text-gray-600">Class: {exam.class}</p>
          <h3 className="mt-4 font-semibold">Students:</h3>
          <ul className="list-disc pl-6">
            {exam.students?.length === 0 && <li>No students found.</li>}
            <Table columns={columns} data={exam.students || []} />
          </ul>
        </div>
      </div>

      {/* Assessment Popup */}
      {showPopup && currentStudent && (
        <AssessmentPopup
          onSubmit={(file) => {
            console.log("AssessmentPopup", file);
            uploadFile(file);
          }}
          isInProgress={uploading}
          exam={exam}
          student={currentStudent}
          onClose={() => setShowPopup(false)}
        />
      )}
      <Modal isOpen={showResultPopup} onClose={() => setShowResultPopup(false)}>
        <div className="p-4 bg-gray-100 rounded-md shadow">
          <h2 className="text-xl font-semibold">Result</h2>
          <p className="text-black-600 font-bold">Name: {currentStudent?.name} {`(${exam.class}${currentStudent?.section})`}</p>
          {/* <h3 className="mt-4 font-semibold">
            Students: {currentStudent?.name}
          </h3> */}
          <h4 className="mt-4 font-semibold">Status: {examReport?.status}</h4>
          {examReport?.status === "Error" && examReport?.reason && (
            <div>
              <p className="text-gray-600">Reason: {examReport?.reason}</p>
            </div>
          )}
          {examReport?.status === "Completed" && (
            <>
              <div className="flex flex-wrap gap-4 p-2">
                <CircularProgress
                  percentage={examReport?.result?.accuracyScore || 0}
                  title="Accuracy Score"
                  size="md"
                  color={getColor(examReport?.result?.accuracyScore || 0)}
                />
                <CircularProgress
                  percentage={examReport?.result?.completenessScore || 0}
                  title="Completeness Score"
                  size="md"
                  color={getColor(examReport?.result?.completenessScore || 0)}
                />
              </div>
              <div className="flex flex-wrap gap-4 p-2">
                <CircularProgress
                  percentage={examReport?.result?.fluencyScore || 0}
                  title="Fluency Score"
                  size="md"
                  color={getColor(examReport?.result?.fluencyScore || 0)}
                />
                <CircularProgress
                  percentage={examReport?.result?.pronunciationScore || 0}
                  title="Pronunciation Score"
                  size="md"
                  color={getColor(examReport?.result?.pronunciationScore || 0)}
                />
                {/* <CircularProgress
                  percentage={75}
                  title="Tasks"
                  size="lg"
                  color="green"
                />
                <CircularProgress
                  percentage={100}
                  title="Completed"
                  size="xl"
                  color="purple"
                /> */}
              </div>
              {/* <p className="text-gray-600">
                Accuracy Score: {examReport?.result?.accuracyScore}
              </p>
              <p className="text-gray-600">
                Completeness Score: {examReport?.result?.completenessScore}
              </p>
              <p className="text-gray-600">
                Fluency Score: {examReport?.result?.fluencyScore}
              </p>
              <p className="text-gray-600">
                Pronunciation Score: {examReport?.result?.pronunciationScore}
              </p> */}
            </>
          )}
          {examReport?.status === "Error" && (
            <div>
              <div className="items-center d-flex w-full text-center">
                <button
                  className="bg-green-500 text-white w-half p-2 rounded-md "
                  onClick={() => {
                    setShowResultPopup(false);
                    setShowPopup(true);
                  }}
                >
                  ReTake Exam
                </button>
              </div>
            </div>
          )}
          {/* <div>takenCount: {examReport?.takenCount}  maxTakenCount: {exam.maxTakenCount}</div> */}
          {examReport?.status !== "Error" && ((examReport?.takenCount) && (exam.maxTakenCount < examReport?.takenCount)) && (
            <div>
              <div className="items-center d-flex w-full text-center">
                <button
                  className="bg-green-500 text-white w-half p-2 rounded-md "
                  onClick={() => {
                    setShowResultPopup(false);
                    setShowPopup(true);
                  }}
                >
                  ReTake Exam
                </button>
              </div>
            </div>
          )}

        </div>
      </Modal>
    </div>
  );
};

export default TakeExam;
