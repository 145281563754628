import { useState } from 'react';
// import { saveFile } from '../services/FileService';

interface useUploadFileOptionsProps {
    onSuccess?: (res: any) => any;
    onError?: (error: Error) => any;
    onFire?: () => void;
    onPercentage?: (percentage: number) => void;
}

export const useUploadFile = (key: string, fn: (fromData: FormData, options: any) => any, options?: useUploadFileOptionsProps) => {
    console.log('key', key);
    const {
        onSuccess = (res: any) => res,
        onError = (error: any) => error,
        onFire = () => null,
        onPercentage = (num: number) => null
    } = options || {};

    const [percentage, setPercentage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState<any>();
    const [result, setResult] = useState<{ [x: string]: any; }>({});

    const uploadFile = async (data: File, Type?: string) => {
        try {
            const formData = new FormData();
            formData.append('file', data);
            if (Type) {
                formData.append('Type', Type);
            }
            onFire();
            setUploading(true);
            setResult({});
            const fileRes = await fn(formData, {
                onUploadProgress: (progressEvent: any) => {
                    const progress = `${Math.ceil((progressEvent.loaded / progressEvent.total) * 100)}`;
                    onPercentage(+progress);
                    console.log(progress);
                    setPercentage(progress);
                }
            });
            console.log('fileRes', fileRes);
            setPercentage('');
            setUploading(false);
            setResult(fileRes.data);
            onSuccess(fileRes.data);
            return result;
        } catch (error: any) {
            onError(error);
            setUploadError(error.message);
            setPercentage('');
            setUploading(false);
            return null;
        }
    };

    return { percentage, uploading, uploadFile, error: uploadError, data: result };
};

