import React, { useState, useRef } from 'react';
import { toast, ToastOptions, TypeOptions, ToastPosition } from 'react-toastify';


export const useToast = () => {
    const showToast = (
        variant: TypeOptions,
        message: string,
        toastId?: string | number
    ) => {
        toast(message, {
            type: variant,
            toastId: toastId ? toastId : undefined,
            autoClose: toastId ? undefined : 4000,
            position: 'top-right',
        });
    };

    const updateToast = (
        variant: TypeOptions,
        message: string,
        toastId: string | number
    ) => {
        toast.update(toastId, {
            type: variant,
            render: message,
        });
    };

    const dismissToast = (key: number | string) => {
        toast.dismiss(key);
    };

    return { showToast, updateToast, dismissToast };
};




// export default function useToast() {
//     return {
//         showToast: () => { },
//         dissmisToast: () => { },
//     };
// }