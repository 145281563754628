// services/authService.ts
// import api from './api';
import { getDataWithQueryParams, getData, postData, putData, deleteData, fileUpload } from './api';

export interface LoginCredentials {
    email: string;
    password: string;
}

export const login = async (credentials: LoginCredentials) => {
    return  await postData('/auth/login', credentials);
};

export const signup = async (credentials: LoginCredentials) => {
    return await postData('/users/signup', credentials);
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const setToken = (token: string) => {
    localStorage.setItem('token', token);
};

export const logout = () => {
    // localStorage.removeItem('token');
    localStorage.clear();
};