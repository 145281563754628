import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faChalkboardTeacher,
    faUserGraduate,
    faBook,
    faChartBar,
    faCog,
    faSignOutAlt,
    faChevronDown,
    faChevronUp,
    faPlus,
    faClipboardList,
    faArrowLeft,
    faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png'; // Import the logo
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useAuth } from '../hooks';

interface SubMenuItem {
    label: string;
    to: string;
    icon: IconDefinition;
    roles: string[]; // Roles that can access this submenu item
}

interface MenuItem {
    label: string;
    to: string;
    icon: IconDefinition;
    hasSubMenu?: boolean;
    subMenuItems?: SubMenuItem[];
    isOpen?: boolean;
    hoverBgColor: string;
    darkHoverBgColor: string;
    textColor: string;
    darkTextColor: string;
    activeTextColor: string;
    activeBgColor: string;
    roles: string[]; // Roles that can access this menu item
}


const menuItems: MenuItem[] = [
    {
        label: 'Home',
        to: '/dashboard',
        icon: faHome,
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['admin', 'school'] // Accessible by both admin and teacher
    },
    {
        label: 'Schools',
        to: '/schools',
        icon: faChalkboardTeacher,
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['admin'] // Only accessible by admin
    },
    {
        label: 'Students',
        to: '/students',
        icon: faUserGraduate,
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['admin', 'school'] // Accessible by both admin and teacher
    },
    // isPractice
    {
        label: 'Practice',
        icon: faBookOpen,
        to: '/practice',
        hasSubMenu: true,
        isOpen: false,
        subMenuItems: [
            {
                label: 'Create Practice',
                to: '/create-practice',
                icon: faPlus,
                roles: ['school'] // Only accessible by teacher
            },
            {
                label: 'Take Practice',
                to: '/take-practice',
                icon: faClipboardList,
                roles: ['school'] // Only accessible by teacher
            }
        ],
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['school'] // Accessible by both admin and teacher
    }, 
    {
        label: 'Exams',
        icon: faBook,
        to: '/exams',
        hasSubMenu: true,
        isOpen: false,
        subMenuItems: [
            {
                label: 'Create Exam',
                to: '/create-exam',
                icon: faPlus,
                roles: ['school'] // Only accessible by teacher
            },
            {
                label: 'Take Exam',
                to: '/take-exam',
                icon: faClipboardList,
                roles: ['school'] // Only accessible by teacher
            }
        ],
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['school'] // Accessible by both admin and teacher
    },
    {
        label: 'Reports',
        to: '/reports',
        icon: faChartBar,
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['admin'] // Only accessible by admin
    },
    {
        label: 'Settings',
        to: '/settings',
        icon: faCog,
        hoverBgColor: 'bg-blue-100',
        darkHoverBgColor: '',
        textColor: 'text-gray-800',
        darkTextColor: '',
        activeTextColor: 'text-[#3A7BD5]',
        activeBgColor: 'bg-blue-100 ',
        roles: ['admin'] // Only accessible by admin
    }
];



interface SidebarProps {
    isOpen: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
    // const [isExamsSubMenuOpen, setExamsSubMenuOpen] = useState(false);
    const [menu, setMenu] = useState<MenuItem[]>([...menuItems]); // [menus, setMenus]
    const { userRole, logout } = useAuth();
    const navigate = useNavigate();


    const toggleSubMenu = (currMenu:MenuItem) => {
        const tempMenu = menu.map((item, index) => {
            if(item.label === currMenu.label){
                item.isOpen = !item.isOpen;
            }
            return item
        })
        setMenu(tempMenu);
    };

    const location = useLocation(); // Get current location

    const isActiveLink = (path: string) => location.pathname === path;

    //   const blueColor = '#3A7BD5'; // The blue color you shared earlier

    return (
        <aside
            className={`fixed top-16 left-0 h-[calc(100vh-4rem)] p-4 bg-white  shadow-lg z-10 transition-transform duration-300 ease-in-out transform ${isOpen ? 'w-64' : 'w-20'
                }`}
        >
            {/* Back arrow icon (Red) */}
            <div className="flex justify-end pr-2">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-lg text-red-600  cursor-pointer hover:text-red-700 transition-colors"
                    onClick={toggleSidebar}
                />
            </div>

            {/* Logo */}
            <div className={`flex items-center space-x-2 pb-6 border-b border-gray-200 ${isOpen ? 'justify-start' : 'justify-center'}`}>
                {isOpen && <img src={logo} alt="Curioschools Logo" className="w-4/5 h-auto" />} {/* Adjusted logo size */}
            </div>

            {/* Navigation Links */}
            <nav className="mt-6">
                {menuItems
                    .filter(item => item.roles.includes(userRole)) // Only show menu items accessible to the user's role
                    .map((item, menuIdx) => (
                        <div key={menuIdx} className="relative my-[1px]">
                            {!item.hasSubMenu ? (
                                <Link
                                    to={item.to}
                                    className={`relative flex items-center my-[1px] hover:${item.hoverBgColor} hover:${item.darkHoverBgColor} rounded-md group transition-all duration-200 px-4 py-3 ${isActiveLink(item.to || '') ? `${item.activeBgColor} ${item.activeTextColor}` : ''
                                        }`}
                                >
                                    <FontAwesomeIcon
                                        icon={item.icon}
                                        className={`text-lg transform scale-120 transition-all duration-200 group-hover:text-[#3A7BD5] ${isActiveLink(item.to || '') ? item.activeTextColor : ''}`}
                                    />
                                    {isOpen && <span className="ml-3">{item.label}</span>}
                                </Link>
                            ) : (
                                <>
                                    <button
                                        onClick={() => toggleSubMenu(item)}
                                        className={`relative flex items-center hover:${item.hoverBgColor}  rounded-md group transition-all duration-200 px-4 py-3 w-full ${isActiveLink('/create-exam') || isActiveLink('/take-exam') ? `${item.activeBgColor} ${item.activeTextColor}` : ''
                                            }`}
                                    >
                                        
                                        <FontAwesomeIcon
                                            icon={item.icon}
                                            className={`text-lg  transform scale-120 transition-all duration-200 group-hover:text-[#3A7BD5] ${isActiveLink('/create-exam') || isActiveLink('/take-exam') ? item.activeTextColor : ''}`}
                                        />
                                        {isOpen && <span className="ml-3">{item.label}</span>}
                                        {isOpen && (
                                            <FontAwesomeIcon
                                                icon={item.isOpen ? faChevronUp : faChevronDown}
                                                className="ml-auto  group-hover:text-[#3A7BD5]"
                                            />
                                        )}
                                    </button>

                                    {/* Render Submenu */}
                                    {item.hasSubMenu && item.isOpen && (
                                        <div className={`ml-6 ${isOpen ? '' : 'hidden'}`}>
                                            {item.subMenuItems
                                                ?.filter(subItem => subItem.roles.includes(userRole)) // Filter submenu items based on roles
                                                .map((subItem, subIndex) => (
                                                    <Link
                                                        key={subIndex}
                                                        to={item.to + subItem.to}
                                                        className={`block my-[1px] px-4 py-2 hover:${item.hoverBgColor} hover:${item.darkHoverBgColor} rounded-md transition-all duration-200 ${isActiveLink(subItem.to) ? `${item.activeBgColor} ${item.activeTextColor}` : ''
                                                            }`}
                                                    >
                                                        <FontAwesomeIcon icon={subItem.icon} className="mr-2 group-hover:text-[#3A7BD5]" />
                                                        {subItem.label}
                                                    </Link>
                                                ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                <div
                    // to="/login"
                    className="relative flex items-center text-red-600 hover:bg-red-100 rounded-md group transition-all duration-200 px-4 py-3 hand cursor-pointer"
                    onClick={() => {
                        logout();
                        navigate('/login');
                    }}
                >
                    <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="text-lg transform scale-120 transition-all duration-200 group-hover:text-red-700"
                    />
                    {isOpen && <span className="ml-3">Logout</span>}
                </div>
            </nav>
        </aside>
    );
};

export default Sidebar;
