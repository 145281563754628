import React, { useState, useEffect } from 'react';
import { useToast } from "../../hooks";
import { useApi } from "../../hooks/useNew";
import { getExams } from "../../services/examService";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from 'react-router-dom';
import { IExam } from '../../interfaces';
import { humanReadableDate } from '../../utils';


const columns: ColumnDef<IExam>[] = [
    {
        accessorKey: "title",
        header: "Exam Name",
        cell: (info) => {
            const originalData = info?.row?.original || {};
            return (
                <Link to={`/exams/take-exam/${originalData.id}`} className="flex items-center text-sky-500">
                    {originalData.title}
                </Link>
            );
        }
    },

    {
        accessorKey: "type",
        header: "Type",
    },
    {
        accessorKey: "date",
        header: "Created Date",
        cell: (info) => {
            const originalData = info?.row?.original || {};
            return humanReadableDate(originalData.createdAt);
        }
    },
    {
        accessorKey: "class",
        header: "Class",
    },
    {
        accessorKey: "section",
        header: "Section",
    },
    {
        accessorKey: "totalStudents",
        header: "Total Students"
    },
    {
        accessorKey: "totalStudentsTakeExam",
        header: "Total Take Exam"
    }
];

const Exams: React.FC<{ isPractice?: boolean }> = ({ isPractice = false }) => {
    console.log("Exams isPractice", isPractice)
    const { showToast, updateToast, dismissToast, } = useToast();
    const {
        data: exams = [],
        isLoading: isFetchingExams,
        refetch: fetchExams,
        pagination,
    } = useApi<IExam[]>(
        "ExamList",
        () =>
            getExams({
                query: "",
                pageNumber: 1,
                pageSize: 100,
                isPractice: isPractice
            }),
        {
            enabled: false,
            onSuccess: (response: any) => {
                console.log(response);
                return response;
            },
            onError: (error: Error) => {
                showToast("error", error.message);
            },
        },
    );

    useEffect(() => {
        fetchExams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPractice]);


    return (
        <div className="p-4 m-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-2xl font-semibold mb-4">{isPractice ? 'Practice' : 'Take'} Exam</h1>
            <Table columns={columns} data={exams} onRowClick={(row) => {
                console.log(row);
            }} />
        </div>
    );
};

export default Exams;
